@import "../../../../assets/sass/main.scss";

#slider {
  margin-top: 55px;
  .carousel {
    position: relative;
    &-indicators {
      bottom: 20px;
      li {
        width: 10px;
        height: 10px;
        @include bd-radius(50%);
      }
      .active {
        background-color: color("primary");
      }
    }
    .carousel__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      top: 0;
      left: 0;
      background: linear-gradient(to top, rgb(22, 22, 22), transparent);
      display: flex;
      justify-content: center;
      align-items: center;
      .carousel_popup {
        img {
          opacity: 0.8;
          transition: all linear 0.3s;
          &:hover {
            opacity: 1;
            transform: scale(1.05);
          }
        }
      }
    }
    &-control-prev,
    &-control-next {
      .carousel-control-btn {
        &:hover {

        }
      }
    }
  }
}

@include MediaQuery($lg) {
  .modal-lg {
    width: 900px;
  }
  .carousel-control-btn {
    height: 50px;
    width: 50px;
    img {
      height: 50px;
      width: 50px;
    }
  }
  .modal-video-body {
    width: 90% !important;
  }
}

@include MediaQuery($md) {
  .carousel-control-btn {
    width: 50px;
    height: 50px;
  }
}

// @include MediaQuery($sm) {
//   #carousel {
//     display: none;
//   }
// }

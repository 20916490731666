.new {
    // margin-top: 2rem;
    padding-top: 80px;
    // background: url(../../assets/img/back-news.png);
    background-size: contain;
    background-repeat: no-repeat;
    .row {
      > div {
        margin-bottom: 25px;
      }
    }
    .nav-tabs {
      margin-bottom: 20px;
      .nav-link {
        border: none;
        font-size: 30px;
        transition: all 0.2s;
        padding: 0 20px;
        background-color: transparent;
        &:hover {
          transform: scale(1.1);
        }
        &.active {
          color: #fa5238;
          transform: scale(1.1);
        }
      }
    }
    ul {
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        border: none;
        color: black;
        font-weight: 600;
        font-size: 23px;
      }
    }
    img {
      width: 100%;
      border-radius: 4px;
    }
    p {
      font-size: 13px;
      color: #4a4a4a;
    }
    .like {
      text-decoration: none;
      color: #bbb;
      margin-right: 10px;
      font-size: 12px;
      img {
        width: 15px;
        &:hover {
          transform: scale(1.2);
          transition: all 0.1s;
        }
      }
      font-weight: normal;
    }
    .title {
      text-decoration: none;
      color: black;
      font-weight: bold;
      line-height: 20px;
      transition: all 0.3s;
      display: inline-block;
      margin: 8px 0;
      &:hover {
        color: #fb4226;
      }
    }
    .new__item {
      display: flex;
      margin-bottom: 20px;
      img {
        border-radius: 5px;
      }
      > div:first-child {
        width: 80px;
        margin-right: 10px;
      }
      .reditem {
        &:hover {
          color: #fb4226;
        }
      }
      a {
        text-decoration: none;
        color: black;
        display: inline-block;
      }
    }
    .new__button {
      width: 100%;
      margin: 50px 0;
      text-align: center;
      button {
        border: solid 1px rgba(0, 0, 0, 0.329);
        padding: 8px 25px;
        color: #949494;
        background-color: white;
        border-radius: 5px;
        transition: all 0.3s;
        &:hover {
          background-color: #fb4226;
          color: white;
          border-color: transparent;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .new {
      padding-top: 40px;
      .nav-tabs {
        .nav-link {
          font-size: 17px;
        }
      }
    }
  }
  
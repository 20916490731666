@import "../../../../assets/sass/main.scss";

.filmBlock {
  padding: 55px 55px 0px;
  // padding: 3rem;

  .nav-tabs {
    // margin-bottom: 3rem;
    border-bottom: none;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav-item {
      .nav-link {
        border: none;
        font-size: 1.3rem;
        font-weight: 600;
        color: #000;
        padding: 10px 15px;

        &.active {
          font-size: 1.6rem;
          color: $text-hightlight-color;
        }

        &:hover {
          font-size: 1.6rem;
        }
      }
    }
  }

  .tab-content {
    .item {
      display: block;
      padding: 1rem 0.5rem;
      position: relative;
      transition: all 0.2s linear;
      //   min-height: 500px;
      //   margin: 0 0.5rem;
      // &:hover {
      //   transition: all 6.1s linear;
      // }
      &:hover .film .film-block-images::after {
        transform: scale(1);
      }
      &:hover .button-player {
        transform: translateX(0);
        transition: all 0.4s ease-out;
        opacity: 1;
        visibility: visible;
      }
      &:hover .button-purchase {
        transform: translateX(0);
        transition: all 0.4s ease-in;
        opacity: 1;
        visibility: visible;
      }
      &:hover::before {
        content: "";
        display: block;
        width: 100%;
        height: 1rem;
        position: absolute;
        bottom: -1rem;
        left: 0;
      }
      .film {
        position: relative;
        transition: all 0.8s linear;
        .film-block-images {
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            // background-image: linear-gradient(to top, #000, transparent);
            background-color: rgba(0, 0, 0, 0.7);
            transform: scale(0);
            transition: all 0.3s linear;
            top: 0;
            left: 0;
            position: absolute;
          }
        }
        .thumbnail {
          height: 350px;
          width: 100%;
          border-radius: 3px;
          position: relative;
          display: block;
        }
        // .film-type {
        //   position: absolute;
        //   top: -2rem;
        //   left: -1.5rem;
        //   width: 10rem;
        // }
        .film-point {
          position: absolute;
          top: 1rem;
          right: 1rem;
          background-color: rgba(12, 27, 54, 0.8);
          border-radius: 3px;
          border: 1px solid #1f2e46;
          padding: 0 0.3rem 0.3rem;
          color: white;

          p {
            margin-bottom: 0;
            text-align: center;
            font-size: 1.1rem;
            font-weight: 600;
          }
          .setStar {
            display: flex;
            .smallStar,
            .halfStar {
              width: 0.6rem;
              height: 0.6rem;
            }
          }
        }
        .film-point-coming {
          top: 85%;
        }
      }
      .film-info {
        margin-top: 0.5rem;
        .ageType {
          font-size: 14px;
          font-weight: 600;
          background-color: $color-primary;
          color: #fff;
          padding: 1px 6px;
          border-radius: 4px;
          margin-right: 0.2rem;
        }
        .ageTypeKid {
          background-color: $color-primary;
          padding: 0.1rem 0.8rem;
        }
        .nameFilm {
          margin-bottom: 0.2rem;
          font-weight: 600;
          line-height: 1.5;
          height: 3em;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .lengthy {
          font-size: 13px;
          color: #4a4a4a;
        }
      }

      // .button-player {
      //   @include button-player();
      //   width: 4rem;
      //   height: 4rem;
      //   position: absolute;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -100%);
      // }
      .button-config {
        // position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        height: 100%;
        width: 100%;
      }
      .ul {
        // width: 100%;
        list-style: none;
        width: 100%;
        height: 100%;
        .li-1 {
          position: absolute;
          top: 30%;
          left: 20%;
          width: 100%;
        }
        .li-2 {
          position: absolute;
          top: 45%;
          left: 20%;
          width: 100%;
        }
      }
      .button-player {
        color: white;
        // position: absolute;
        font-size: 14px;
        border: 2px solid $color-primary;

        background-color: $color-primary;
        padding: 10px 0;
        width: 60%;
        display: block;
        transform: translateX(-130%);
        opacity: 0;
        visibility: hidden;
        &:hover {
          background-color: transparent;
          transition: all 0.3s linear;
        }
      }

      .button-purchase {
        color: white;
        font-size: 14px;
        background-color: transparent;
        border: 2px solid $color-primary;
        display: block;
        width: 60%;
        padding: 10px 0;
        transform: translateX(130%);
        opacity: 0;
        visibility: hidden;

        &:hover {
          background-color: $color-primary;
          transition: all 0.3s linear;
        }
      }
    }
  }

  .slick-next:before {
    content: url("../../../../assets/img/nextArrow.png");
  }
  .slick-prev:before {
    content: url("../../../../assets/img/prevArrow.png");
  }
  .slick-prev,
  .slick-next {
    top: 45%;
  }
  .slick-prev {
    left: -50px;
  }
  .slick-next {
    right: -10px;
  }
  .slick-prev:focus:before {
    opacity: 0.75;
  }
  .slick-prev:hover:before {
    opacity: 1;
  }
  .slick-next:focus:before {
    opacity: 0.75;
  }
  .slick-next:hover:before {
    opacity: 1;
  }

  .slick-prev:before,
  .slick-next:before {
    opacity: 0.75;
    transition: all 0.3s linear;
  }
}

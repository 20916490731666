@import "../../../../assets/sass/main.scss";

.movie__detail_information_title {
    margin: spacing(5) spacing(0);

 p{
    @include text(color("white"), $body, $fwMedium);

 }
 span{
     
    @include text(color("white"), $body, $fwRegular);
 }
}
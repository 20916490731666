@import "../../../../assets/sass/main.scss";
.booking__combo {
  .combo__list {
    .combo__item {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      .item__left {
        img {
          width: 100%;
        }
      }
      .item__mid {
        span {
          font-size: 18px;
          font-weight: 500;
          color: $color-primary;
        }
        p {
          color: #9b9b9b;
          margin: 10px 0;
        }
        .price {
          color: $color-secondary;
          font-size: 18px;
          font-weight: 500;
        }
      }
      .item__right {
        display: flex;
        justify-content: center;
        align-items: center;
        i:first-child {
          color: #9b9b9b;
          cursor: pointer;
        }
        span {
          margin: 0 10px;
        }
        i:last-child {
          color: $color-primary;
          cursor: pointer;
        }
      }
    }
  }
}

@import "../../../assets/sass/main.scss";
.booking {
  text-align: left;
  margin-top: 100px;
  .booking__left {
    .booking__left-header {
      display: flex;
      justify-content: space-between;
      .cinema__left {
        .cinema__wrap {
          display: flex;
          align-items: center;
          img {
            width: 50px;
            margin-right: 10px;
          }
          .cinema__text {
            h6 {
              color: $color-primary;
              font-weight: 500;
              span {
                color: #999;
              }
            }
            p {
              color: #999;
              font-size: 14px;
            }
          }
        }
      }
      .cinema__right {
        .timer {
          h6 {
            color: #9b9b9b;
          }
          p {
            text-align: center;
            color: $color-primary;
            font-size: 25px;
            font-weight: bold;
          }
        }
      }
    }
    .booking__left-room {
      .room__screen {
        .screen__img {
          img {
            width: 100%;
          }
        }
      }
      .room__seat {
        .seat__wrap {
          display: flex;
          margin: auto;
          max-width: 900px;
          flex-wrap: wrap;
          justify-content: center;

          .seat__item {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            margin: 0.5rem;
            background-color: #3e515d;
            cursor: pointer;
            color: white;

            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &:after {
              border: 1px solid white;
              border-radius: 5px;

              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 80%;
              height: 80%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      .room__note {
        text-align: center;
        display: flex;
        margin-top: 80px;
        display: flex;
        justify-content: space-around;
        .note__item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            margin: 0.5rem;
            background-color: #3e515d;
            position: relative;
            &::after {
              border: 1px solid white;
              border-radius: 5px;

              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              width: 80%;
              height: 80%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
  .booking__right {
    box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.3);
    padding: 20px;
    .booking__right-content {
      .price-total {
        color: $color-secondary;
        font-weight: 500;
        font-size: 20px;
        border-bottom: 1px dashed #bbb;
        padding-bottom: 20px;
      }
      .booking__right-movie {
        border-bottom: 1px dashed #bbb;
        padding-bottom: 20px;
        margin: 20px 0;
        h6 {
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 0;
          color: $color-primary;
        }
        p {
          padding: 5px 0;
          font-size: 14px;
        }
        span {
          font-size: 14px;
        }
      }
      .booking__right-seat {
        border-bottom: 1px dashed #bbb;
        display: flex;

        margin-bottom: 20px;
        padding-bottom: 20px;
        span {
          color: $color-primary;
          font-weight: 500;
          //   font-size: 18px;
        }
        p {
          color: $color-secondary;
          text-align: right;
        }
      }
      .booking__right-combo {
        &.open-combo {
          .combo__img {
            i {
              transform: rotate(180deg);
            }
          }
        }

        cursor: pointer;
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px dashed #bbb;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .combo__img {
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: rgb(117, 116, 116);
            font-size: 25px;
            margin-right: 5px;
          }
          img {
            width: 30px;
          }
        }
        .combo__price {
          color: $color-secondary;
        }
      }
      .booking__right-form {
        border-bottom: 1px dashed #bbb;
        padding-bottom: 10px;
        margin: 30px 0;
      }
    }
    .booking__right-purchase {
      button {
        // width: 100%;
        // padding: 15px 5px;
        // background: linear-gradient(to left, #44c020 0, #108f3e 100%);
        // border-radius: 5px;
        // color: white;
        // justify-content: center;
        // transition: all linear 0.3s;
        // &:hover {
        //   background: linear-gradient(to left, #108f3e 0, #108f3e 100%);
        // }
        @include button-purchase--fade();
      }
    }
  }
}
.seat--gold {
  background-color: #f7b500 !important;
}
.seat--green {
  background-color: #44c020 !important;
}
.seat--red {
  color: white;
  background-color: #e71a0f !important;
  opacity: 0.3;
  pointer-events: none;
}

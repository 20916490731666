@import "../../../../assets/sass/main.scss";
.show__info {
  padding: spacing(5) spacing(0);
  padding-bottom: spacing(7);
  display: flex;
  justify-content: center;
  align-items: center;

  &__movies {
    max-width: 870px;
    height: 750px;

    background-color: color("white");
    border-radius: 5px;

    &__pcinema__name {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: spacing(1.5) spacing(0);
      border-bottom: 1px solid rgba(color("darkergrey"), $alpha: 0.2);
      opacity: 0.5;
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
      &__active {
        opacity: 1;
      }
      & > [class*="col-"] {
        padding-right: 0;
        padding-left: 0;
      }
      &__logo {
        img {
          width: 50px;
          height: 50px;
        }
      }

      h4 {
        @include text(color("black"), $body, $fwMedium);
      }
    }
    &__time {
      display: flex;
      overflow: hidden;
      overflow-x: scroll;
      scrollbar-width: thin;
      border-left: 1px solid rgba(color("darkergrey"), $alpha: 0.2);

      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-width: 100px;
        padding: spacing(1.4) spacing(0);
        cursor: pointer;

        p,
        h6 {
          margin-bottom: 0;
        }
        p {
          @include text(color("black"), $body, $fwMedium);
        }
        h6 {
          @include text(color("black"), 20px, $fwMedium);
        }

        &__active {
          p,
          h6 {
            color: color("primary");
          }
        }
      }
    }
    &__list {
      max-height: 650px;
      overflow: hidden;
      overflow-y: scroll;

      border-left: 1px solid rgba(color("darkergrey"), $alpha: 0.2);
    }
  }
}
.strollbar__bottom {
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(color("darkergrey"), $alpha: 0.2);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(color("darkergrey"), $alpha: 0.4);
  }
}
@include MediaQuery($lg) {
  .show__info {
    &__movies {
      max-width: 700px;
      height: 840px;
    }
  }
}
@include MediaQuery($sm) {
  .show__info {
    &__movies {
      max-width: 100%;
      height: 1140px;
      img {
        margin-left: spacing(1.5);
      }
      &__pcinema__name {
        h4 {
          text-align: left;
        }
      }
    }
    &__movies__time__item {
      padding-left: 0;
      max-width: 80px;
    }
  }
  .col-sm-12.col-md-9.pr-0 {
    padding-left: 0;
  }
}

@include MediaQuery($xs) {
  .show__info {
    &__movies {
      height: 840px;
    }
  }

  .show__info__movies__pcinema__name {
    border: none;
    margin: spacing(0) spacing(2);
    h4 {
      display: none;
    }
  }
  .reponsive-2 {
    display: flex;
    justify-content: space-around;
    border-bottom: 1px solid #ccc;
    overflow: hidden;
    overflow-x: scroll;
    scrollbar-width: thin;
  }
}

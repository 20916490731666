.filmHot {
  margin-top: 80px;
  // padding-top: 80px;
  position: relative;
  .background-img {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .containers {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .col {
      .circle {
        border: 10px solid rgba(255, 255, 255, 0.29);
        border-radius: 100%;
      }
      .btn-player {
        background-color: #ff4444;
        border-radius: 100%;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;

        @-webkit-keyframes pulse {
          to {
            box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
          }
        }
        @-moz-keyframes pulse {
          to {
            box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
          }
        }
        @-ms-keyframes pulse {
          to {
            box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
          }
        }
        @keyframes pulse {
          to {
            box-shadow: 0 0 0 40px rgba(232, 76, 61, 0);
          }
        }

        box-shadow: 0 0 0 0 rgba(225, 225, 225, 0.6);
        -webkit-animation: pulse 1.2s infinite;
        -moz-animation: pulse 1.2s infinite;
        -ms-animation: pulse 1.2s infinite;
        animation: pulse 1.2s infinite;

        i {
          color: white;
          z-index: 1000;
          font-size: 24px;
        }
      }
    }
  }
}
@media screen and (max-width: "768px") {
  .filmHot {
    .containers {
      .col {
        .btn-player {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}
@media screen and (max-width: "576px") {
  .filmHot {
    .containers {
      .col {
        .btn-player {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

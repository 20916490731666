@import "../../assets/sass/main.scss";

footer {
  padding-top: 40px;
  background-color: #1a1a1a;
  color: white;
  .container {
    .footer-items {
      text-align: left;
      h5 {
        padding-top: 20px;
        color: $color-text-white;
        padding-bottom: 5px;
        &::before {
          content: "";
          border-left: 5px solid $color-primary;
          padding-right: 10px;
        }
      }
      .list-unstyled {
        li {
          padding-bottom: 5px;
          a {
            color: $color-text-white;
            font-size: 14px;
            i {
              padding-right: 10px;
              font-size: 10px;
            }
          }
          &:hover a {
            color: $color-primary;
          }
        }
      }
      .list-unstyled.contact-mxh {
        li {
          display: inline-flex;
          padding-right: 8px;
          i {
            font-size: 25px;
          }
          &:hover #facebook {
            color: #3b5998;
          }
          &:hover #instagram {
            color: #fbad50;
          }
        }
      }
      @media screen and (max-width: 576px) {
      }
    }
  }
}

.hover-icon {
  font-size: 1rem;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.3);
  }
}

.classesRoot {
  max-width: 100%;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #d6d4d4;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(146, 145, 145);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(134, 133, 133);
  }
  .classesContainer {
    max-height: 500px;
    width: 100%;
    .MuiTableRow-head {
      .MuiTableCell-root {
        background-color: rgb(61, 60, 60);
        color: white;
      }
    }
    .MuiTableBody-root {
      .MuiTableCell-root {
        button {
          i {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            background: linear-gradient(45deg, red, blue);
            border-radius: 50%;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }
}

@import "../../../../assets/sass/main.scss";
.cinemaBlock {
  padding-top: 80px;
  .container {
    //   height: 700px;
    //   max-height: 700px;
    border: 1px solid $border-cinema;
  }
  img {
    width: 50px;
  }
  .nav-logo {
    .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .nav-item {
      img {
        border-bottom: 1px solid $border-cinema;
        padding-bottom: 16px;
      }
      &:not(.active) {
        opacity: 0.5;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .nav-location {
    border-left: 1px solid $border-cinema;
    border-right: 1px solid $border-cinema;
    height: 605px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #e8e3e3;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: #c4c2c2;
    }
    &::-webkit-scrollbar-track {
      border-radius: 50px;
    }
    .nav-item {
      &:not(.active) {
        opacity: 0.5;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .tab-content-logo {
    .location {
      border-bottom: 1px solid $border-cinema;
      padding-bottom: 16px;
    }
    .location,
    .film-wrap {
      display: flex;
      align-items: center;
      .info {
        margin-left: 1rem;
        .cinema-name {
          font-size: 15px;
          margin-bottom: 0;

          .name-hightlight {
            color: #8bc541;
          }
          .name-hightlight--cns {
            color: #df0d7a;
          }
          .name-hightlight--ddc {
            color: #9c9c9c;
          }
          .name-hightlight--megags {
            color: #e5a813;
          }
        }
        .address {
          font-size: 13px;
          margin-bottom: 0;
          color: #949494;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // white-space: nowrap;
        }
        .detail {
          font-size: 13px;
          color: #fb4226;
          font-weight: 500;
        }
      }
    }
  }
  .tab-content-location {
    padding-right: 0;
    .list-movie {
      height: 605px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #e8e3e3;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #c4c2c2;
      }
      &::-webkit-scrollbar-track {
        border-radius: 50px;
      }
    }
    .film-item {
      border-bottom: 1px solid $border-cinema;
      padding: 8px 16px 8px 0;
      cursor: pointer;
      .film-wrap {
        margin-bottom: 15px;
        .info {
          .ageType {
            background-color: $color-primary;
            color: white;
            border-radius: 5px;
            padding: 0.05rem 0.5rem;
          }
          .ageTypeKid {
            background-color: $color-secondary;
            padding: 0.03rem 0.9rem;
          }
        }
      }
      .type-time {
        // margin-top: 15px;
        .type {
          margin-bottom: 0;
          font-weight: 600;
          margin-bottom: 5px;
        }
        .time {
          display: inline-block;
          margin: 0 10px 10px 0;
          padding: 0.3rem 0.8rem;
          font-size: 14px;
          transition: all 0.2s;
          background-color: rgba(246, 246, 246, 0.5);
          border-radius: 7px;
          color: #9b9b9b;
          border: 1px solid #e4e4e4;
          span {
            color: $color-secondary;
            font-weight: 600;
            font-size: 18px;
          }
          &:hover span {
            color: $color-primary;
          }
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    img {
      width: 37px;
    }
    .nav-logo {
      .nav-link {
        padding: 0.5rem 0 0.5rem 0.7rem;
      }
    }
  }
  @media screen and (max-width: 768px) {
    // img {
    //   width: 30px;
    // }
    .nav-logo {
      .nav-link {
        padding: 0.5rem 0 0.5rem 0.5rem;
      }
    }

    .tab-content-location .film-item .type-time .time {
      padding: 0.1rem 0.5rem;
    }
    .tab-content-logo .location .info .address {
      display: none;
    }
  }
  @media screen and (max-width: 599px) {
  }
}

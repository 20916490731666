@import "../../../../assets//sass/main.scss";
.addMovie {
  background-color: white;
  border-radius: 20px;

  .title {
    color: rgb(41, 180, 41);
    font-weight: 600;
    padding: 10px 0;
  }
  form {
    .form-group {
      text-align: left;
      label {
        color: #293fb6;
        font-weight: 600;
      }
    }
    button {
      //   @include button-purchase--fade();
      margin: 20px;
      padding: 10px 30px;
      //   background: linear-gradient(135deg, orange 40%, cyan);
      background: linear-gradient(45deg, red, blue);
      &:hover {
        transition: all linear 0.3s;
        transform: scale(1.1);
      }
    }
  }
}

@import "../../../assets/sass/main.scss";
.wrapper {
  color: #9c6644;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .content {
    width: 60%;
    .form-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      label {
        text-align: left;
      }
    }
    .btn-update {
      margin-top: 40px;
      @include button-purchase--fade();
    }
  }
}

@import "../../../assets/sass/main.scss";
#auth {
  background-image: url("https://scr.vn/wp-content/uploads/2020/08/H%C3%ACnh-n%E1%BB%81n-vector-%C4%91%E1%BA%B9p-2048x1024.jpg");

  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.4);
    position: absolute;
  }
  .login__wrapper {
    position: relative;
    color: white;
    z-index: 1;
    width: 450px;
    max-width: 450px;
    padding: 3rem;
    background-color: rgba(20, 50, 93, 0.9);
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.45);
    .login__logo {
      img {
        width: 80px;
      }
      margin-bottom: 3rem;
    }
    .login__redirect-register {
      margin-top: 1rem;
      .signup {
        margin-left: 0.5rem;
        color: $color-primary;
      }
    }
    .login__main {
      .form-group {
        text-align: left;
        input {
          width: 100%;
        }
      }
      button {
        margin-top: 3rem;
        color: white;
        @include button-purchase--fade();
      }
    }
    .login__close {
      position: absolute;
      top: -15px;
      right: -15px;
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 50%;

      display: flex;
      justify-content: center;
      align-items: center;

      i {
        color: rgba(15, 50, 120, 0.9);
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }
}

@import "../../../../assets/sass/main.scss";
@import "../../../../../node_modules/react-modal-video/scss/modal-video.scss";
.detail__top {
  text-align: left;
  color: white;
  position: relative;
  .bg {
    width: 100%;
    margin: -5px -15px 20px 0px;
    filter: blur(8px);
    max-height: 780px;
  }
  .gradient,
  .blurred {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .gradient {
    background: linear-gradient(to top, rgb(10, 32, 41), transparent 100%);
  }

  .detail__content {
    max-width: 870px;
    width: 100%;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .content__left {
      display: flex;
      align-items: center;
      .thumbnail {
        position: relative;
        .button-player {
          @include button-player--fade();
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:hover .button-player {
          // transform: rotate(1);
          transition: all 0.4s ease-out;
          opacity: 1;
          visibility: visible;
        }
        &:hover .overlay {
          @include overlay();
        }
      }
      .info {
        margin-left: 10px;
        .date {
          margin-bottom: 0;
        }
        .title {
          margin: 5px 0;
          button {
            color: white;
            padding: 0 5px;
            background-color: $color-primary;
            cursor: text;
          }
        }
        .button-purchase {
          color: white;
          background-color: $color-primary;
        }
      }
    }
    .content__right {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      // text-align: center;
      //   transform: translateY(25%);
      .circle {
        border: 5px solid greenyellow;
        border-radius: 100%;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 10px;
        background-color: rgba($color: #000000, $alpha: 0.2);
      }
      .evaluate {
        i,
        span {
          color: $color-primary;
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  // .detail__top {
  //   .bg {
  //     filter: none;
  //   }
  // }
}

@import "../../assets/sass/main.scss";
header {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: rgba($color: white, $alpha: 1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  transition: all 0.2s;

 
  width: 100%;
  .navbar {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    // height: 60px !important;
  }
  .navbar-brand {
    img {
      width: 50px;
      margin: 3px 0;
    }
  }
  .navbar-nav {
    width: 100%;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
  }
  .header__center {
    margin-left: 70px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .nav-item {
      margin-right: 20px;
      color: #000;
      transition: all 0.2s;
      font-size: 14px;
      font-weight: 500;
      a {
        text-decoration: none;
        &:hover {
          color: #fb4226;
        }
      }
    }
  }
  .header__right {
    display: flex;
    align-items: center;
    .none-login,
    .login {
      a {
        font-size: 15px;
        color: #9b9b9b;
        img {
          height: auto;
          width: 30px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
    .login {
      a {
        color: #3d3c3c;
      }
      a::after {
        display: none;
      }
      .logout {
        margin-left: 10px;
        font-size: 15px;
        color: #9b9b9b;
        cursor: pointer;
      }
      .dropdown-menu {
        margin-right: auto;
        left: initial;
        right: 0;
        .dropdown-item {
          &:hover {
            background-color: $color-primary;
            color: white;
          }
        }
      }
    }
    .locate-dropup {
      button {
        :focus {
          outline: none;
          box-shadow: none;
        }
        &::after {
          color: #b9b9b9;
          margin-left: 0.5rem;
        }
        .earth {
          color: #9b9b9b;
        }
        .country {
          color: #9b9b9b;
        }
      }
      .dropdown-menu {
        overflow-y: scroll;
        max-height: 300px;
        box-shadow: 0 4px 16px rgba(20, 23, 28, 0.25);
        &::-webkit-scrollbar {
          width: 4px;
          background-color: #e8e3e3;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #9b9b9b;
        }
        a {
          display: block;
          padding: 0 1rem;
          font-size: 15px;
          &:hover {
            background-color: #60c5ef;
            color: white;
          }
        }
      }
    }
    // .navbar-collapse {
    //   background-color: white;
    // }
  }
  @media screen and (max-width: 825px) {
    .header__center {
      flex-grow: none;
      margin-left: -20px;
      // display: block;
      text-align: center;
      .nav-item {
        padding: 10px;
        margin-right: 0;
      }
    }
    .header__right {
      text-align: center;
      // display: block;
      .login {
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .header__center {
      display: block;
      a {
        font-size: 18px;
        font-weight: 400;
      }
    }
    .header__right {
      display: block;
    }
  }
}

.toTop {
  position: fixed;
  z-index: 1000;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  transition: all linear 0.3s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  i {
    background-color: white;
    color: #fb4226;
    border: 1px solid #fb4226;
    border-radius: 50%;
    padding: 8px;
  }
}
.toTop.active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

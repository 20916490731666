@import "../../../../assets/sass/main.scss";
.detail__bottom {
  background-color: rgb(10, 32, 41);
  //   text-align: center;
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-color: initial;
    border-color: initial;
    color: $color-primary;
  }
  .nav-tabs .nav-link {
    border: none;
  }
  .nav {
    display: inline-flex;
    border-bottom: none;
    a {
      color: white;
      font-size: 24px;
      transform: scale(1);
      &:hover {
        color: $color-primary;
        transform: scale(1.2);
      }
    }
  }
}

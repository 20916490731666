@import "../../../../assets/sass/main.scss";
.show__item__item {
  margin: spacing(0) spacing(2);
  padding-bottom: spacing(2);

  border-bottom: 1px solid rgba(color("darkergrey"), $alpha: 0.2);

  &__media {
    cursor: pointer;
    display: flex;
    align-items: center;

    &__img {
      img {
        width: 50px;
      }

      margin-right: spacing(0.5);
    }

    &__content {
      p,
      h3 {
        margin-bottom: 0;
      }

      p {
        @include text(color("lightgrey"), $title2, $fwMedium);
      }
    }
  }

  &__type__time {
    h3 {
      @include text(color("black"), $body, $fwMedium);
      margin-bottom: spacing(2);
    }

    a {
      @include text(color("lightgrey"), $title3, $fwMedium);
      display: inline-block;
      padding: spacing(1) spacing(1.5);
      background-color: color("lightwhite");
      border-radius: 8px;
      border: 1px solid color("lightwhite");
      margin-right: spacing(1);

      &:hover {
        color: color("lightgrey");
        text-decoration: none;
      }

      &:hover span {
        color: color("primary");
      }

      span {
        @include text(color("darkgreen"), $body, $fwMedium);
        transition: 0.3s;
      }

      text-decoration: none;
    }
  }
}

// reponsive
@include MediaQuery($sm) {
  .show__item__item {
    &__media {
      img {
        margin-left: 0;
      }
    }
  }
}

// COLOR
// $button-primary: #fb4226;
// $button-secondary: #00ac4d;
$color-primary: #ff4444;
$color-secondary: #00ac4d;
$text-hightlight-color: #fa5238;

// Text
$color-text-white: rgba(255, 255, 255, 0.7);

// BORDER
$border-cinema: rgba(238, 238, 238, 0.88);

// ngt
$colors: (
  "primary": #fb4226,
  "secondary": #fa5238,
  "yellow": #ffc107,
  "green": #7ed321,
  "darkgreen": #108f3e,
  "blue": #17a2b8,
  "darkblue": #337ab7,
  "lightgrey": #9b9b9b,
  "blackgrey": #4a4a4a,
  "darkgrey": #222,
  "darkergrey": #333,
  "black": #000,
  "lightwhite": #e9e9e9,
  "lighterwhite": #f6f6f6,
  "white": #fff,
  "transparent": transparent,
);

//FONT-SIZE
$base: 10px;
$title1: 12px;
$title2: 13px;
$title3: 14px;
$body: 16px;
$h1: 18px;
$h2: 24px;

//FONT-WEIGHT
$fwBold: 700;
$fwMedium: 600;
$fwRegular: 400;
$fwLight: 300;

//SPACING
$spacing: 10px;

// Reponsive
$xs: 576px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

.user-sidebar {
  height: 100vh;
  background-color: #e6ccb2;

  .sidebar {
    padding-top: 40%;
    width: 100%;
    .header {
      margin-bottom: 20px;
      .info {
        p {
          font-weight: 500;
          font-size: 18px;
        }
      }
    }
    .content {
      .styleNavLink {
        display: block;
        text-align: center;
        margin-bottom: 20px;
        width: 100%;
        i {
          margin-right: 5px;
        }
      }
      .styleNavLinkActive {
        color: white;
        background-color: #9c6644;
        padding: 10px 0;
        font-weight: 500;
      }
    }
  }
}

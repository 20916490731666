html {
  font-family: "Roboto", sans-serif;
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
  cursor: pointer;
  transition: all 0.1s linear;
  color: #000;
  &:hover {
    text-decoration: none;
    color: #000;
  }
}

.btn:focus {
  box-shadow: none;
}
button:focus {
  outline: none;
}

p {
  margin-bottom: 0;
}
input {
  &:focus-visible {
    outline: none;
  }
}

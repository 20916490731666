@import "../../../assets/sass/main.scss";
.movieAddAdmin {
  .rows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      .addMovie__link {
        display: flex;
        justify-content: left;
        button {
          margin-left: right;
          padding: 10px 20px;
          background: linear-gradient(45deg, red, blue);
          color: white;
          transition: all 0.3s;
          &:hover {
            color: white;
            transform: scale(1.1);
          }
        }
      }
      i {
        background: linear-gradient(45deg, red, blue);
        color: white;
        padding: 11px 15px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        cursor: pointer;
      }
    }
  }
}

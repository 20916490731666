.booking-history {
  margin-top: 40px;
  .title {
    color: #9c6644;
    margin-bottom: 20px;
  }
  .content {
    & {
      /* width */
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }

      // /* Track */
      ::-webkit-scrollbar-track {
        background: #c9c7c7;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: rgba($color: #000000, $alpha: 0.3);
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: rgba($color: #000000, $alpha: 0.5);
      }
    }
    .table {
      height: 500px;
      overflow: auto;
    }
  }
}
